


































































import Vue, { PropType } from "vue";
import { get_random_color_hash } from "@/utils/colors";
import { format_overall_score, get_name_initials } from "@/utils/global";
import { ListingApplicants } from "@/interfaces/recruiter/jobs/job_listing/listing_applicants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { fetch_latest_work_history } from "@/utils/cv_parser";
import { mapMutations } from "vuex";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { InterviewListingCandidates } from "@/store/modules/recruiter/interfaces";
import {
  CANDIDATE_VIEW_PAYLOAD,
  VIEW_CANDIDATES_DYNAMICALLY,
  SELECTED_CANDIDATE_INTERVIEW_REPORT
} from "@/store/modules/recruiter/constants";

export default Vue.extend({
  name: "ListingCandidates",
  data(): ListingApplicants {
    return {
      matching_applicants: []
    };
  },
  props: {
    applicants: {
      type: Array as PropType<Candidates.Candidates[]>,
      required: true
    },
    job_id: {
      type: Number,
      required: true
    },
    job_title: {
      type: String,
      required: true
    }
  },
  watch: {
    breakpoints() {
      this.applicant_resetting();
    }
  },
  mounted() {
    this.applicant_resetting();
  },
  computed: {
    breakpoints() {
      return this.$vuetify.breakpoint.width;
    },
    Interview() {
      return Interview;
    }
  },
  methods: {
    ...mapMutations("recruiter", {
      set_candidates_view_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      set_candidate_view_payload: CANDIDATE_VIEW_PAYLOAD,
      set_selected_candidate: SELECTED_CANDIDATE_INTERVIEW_REPORT
    }),
    format_overall_score,
    fetch_latest_work_history,
    get_color(applicant: InterviewListingCandidates): number | string {
      return get_random_color_hash(
        `${applicant.first_name} ${applicant.last_name}`
      );
    },
    async navigate(applicant: InterviewListingCandidates | null) {
      if (
        applicant?.id &&
        applicant?.interview_status === Interview.Status.Finished
      ) {
        this.set_selected_candidate(applicant);
      } else {
        this.set_selected_candidate([]);
      }
      this.$emit("view_applicants");
    },
    get_initials(applicant: InterviewListingCandidates): string {
      return get_name_initials(
        `${applicant.first_name} ${applicant.last_name}`
      );
    },
    /**
     * Slice applicants based on different screen sizes
     */
    applicant_resetting() {
      if (this.$vuetify.breakpoint.xs) {
        this.matching_applicants =
          this.applicants.length > 3
            ? this.applicants.slice(0, 3)
            : this.applicants;
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        this.matching_applicants =
          this.applicants.length > 4
            ? this.applicants.slice(0, 4)
            : this.applicants;
      } else if (
        this.$vuetify.breakpoint.mdAndUp &&
        this.$vuetify.breakpoint.lgAndDown
      )
        this.matching_applicants =
          this.applicants.length > 5
            ? this.applicants.slice(0, 5)
            : this.applicants;
      else
        this.matching_applicants =
          this.applicants.length > 7
            ? this.applicants.slice(0, 7)
            : this.applicants;
    }
  }
});
