


























import Vue from "vue";
import { mapGetters } from "vuex";
import InterviewListingHeader from "@/components/recruiter/interview/InterviewListingHeader.vue";
import { HeadData } from "@/interfaces/recruiter/jobs/job_listing/listing_thead";
import InterviewListingBody from "@/components/recruiter/interview/InterviewListingBody.vue";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "InterviewListing",
  components: { InterviewListingBody, InterviewListingHeader },
  data() {
    return {
      table_headers: [] as HeadData[]
    };
  },
  mounted() {
    this.intialize_table_headers();
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    get_site_direction() {
      this.intialize_table_headers();
    }
  },
  methods: {
    intialize_table_headers() {
      this.table_headers = [
        {
          title: this.$t("recruiter.interview.listing.date-col")
        },
        {
          title: this.$t("recruiter.interview.listing.job-col")
        },
        {
          title: this.$t("recruiter.interview.listing.candidates-col")
        },
        {
          title: this.$t("recruiter.interview.listing.actions-col")
        }
      ];
    }
  }
});
